import ProxyService from './Proxy';
import Utils from './Utils';
import config from './config';
import { EmptyResponse, Meetings, ProxyMethod, ProxyType } from './types';

export default class MeetingsService {
  public proxy: ProxyService;
  readonly route = config.urls.meetings;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public async get(params: Meetings.GetParams = {}): Promise<Meetings.Meeting | Meetings.Meeting[]> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async create(params: Meetings.CreateParams = {}): Promise<Meetings.Meeting> {
    const MINUTE = 60;
    const startTimestamp = Math.ceil(Date.now() / 1000);
    const endTimestamp = startTimestamp + 60 * MINUTE;
    const defaultName = new Date(startTimestamp).toLocaleString('en', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
    const defaultParams = {
      name: defaultName,
      start_date: startTimestamp,
      end_date: endTimestamp,
      attendees: [],
      record: false,
      chat: false,
    };
    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route),
      data: { ...defaultParams, ...params },
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async update(id: string | number, params: Meetings.UpdateParams): Promise<Meetings.Meeting> {
    const ajaxParams = {
      type: ProxyMethod.PUT,
      url: Utils.getUrl(this.route, id),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async delete(id: string | number): Promise<EmptyResponse> {
    const ajaxParams = {
      type: ProxyMethod.DELETE,
      url: Utils.getUrl(this.route, id),
      dataType: ProxyType.TEXT,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async getRecordings(id: string | number): Promise<Meetings.Recording[]> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route, 'recordings', id),
    };

    return this.proxy.ajax(ajaxParams);
  }
}
