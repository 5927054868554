import { DateOrTimestamp, SearchCondition } from './common';

export enum MeetingDateMetric {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  WEEK = 'weeks',
}

export namespace ConnectyCubeMeetings {
  export type Attendee = { id?: number; email?: string };
  export type Meeting = {
    _id: string;
    name?: string;
    start_date?: DateOrTimestamp;
    end_date?: DateOrTimestamp;
    attendees?: Attendee[];
    record?: boolean | null;
    chat_dialog_id?: string | null;
    created_at?: DateOrTimestamp;
    updated_at?: DateOrTimestamp;
    host_id?: number;
    public?: boolean;
    scheduled?: boolean;
    notify?: boolean;
    notify_before?: {
      metric: MeetingDateMetric | string;
      value: number; // 'minutes' range [0 - 55], 'hours' range [1 - 24], 'days' range [1 - 30], 'weeks' range [1 - 4]
    };
    timezone?: {
      offset: number; // Minutes UTC offset
      name: string; // Timezone Name
    };
  };
  export type Recording = {
    _id: string;
    room_id: string;
    participants_ids: number[];
    updated_at: string;
    created_at: string;
    download_url: string;
    participants_count: number;
    duration: number;
    size: number;
  };
  // Request parameters type
  export type GetParams = {
    _id?: string;
    limit?: number;
    offset?: number;
    start_at?: SearchCondition<DateOrTimestamp>;
    end_at?: SearchCondition<DateOrTimestamp>;
    updated_at?: SearchCondition<DateOrTimestamp>;
    created_at?: SearchCondition<DateOrTimestamp>;
    scheduled?: SearchCondition<boolean>;
  };
  export type CreateParams = {
    name?: string;
    start_date?: DateOrTimestamp;
    end_date?: DateOrTimestamp;
    attendees?: Attendee[];
    chat?: boolean;
    record?: boolean;
    public?: boolean;
    scheduled?: boolean;
    notify?: boolean;
    notify_before?: {
      metric: MeetingDateMetric | string;
      value: number; // 'minutes' range [0 - 55], 'hours' range [1 - 24], 'days' range [1 - 30], 'weeks' range [1 - 4]
    };
    timezone?: {
      offset: number; // Minutes UTC offset
      name: string; // Timezone Name
    };
  };
  export type UpdateParams = {
    name: string;
    start_date: DateOrTimestamp;
    end_date: DateOrTimestamp;
    attendees: Attendee[];
    record?: boolean;
    public?: boolean;
    scheduled?: boolean;
    notify?: boolean;
    notify_before?: {
      metric: MeetingDateMetric | string;
      value: number; // 'minutes' range [0 - 55], 'hours' range [1 - 24], 'days' range [1 - 30], 'weeks' range [1 - 4]
    };
    timezone?: {
      offset: number; // Minutes UTC offset
      name: string; // Timezone Name
    };
  };
}
