import Utils from '../Utils';
import { XMPPClient } from '../messaging/xmpp';
import ChatUtils from '../messaging/ChatUtils';
import WebRTCHelpers from './WebRTCHelpers';
import { Calls, CallSignalingModuleIdentifier, CallSignalingType } from '../types';

export default class WebRTCSignalingProvider {
  public signalingConnection: XMPPClient;

  constructor(signalingConnection: XMPPClient) {
    this.signalingConnection = signalingConnection;
  }

  public sendCandidate(userID: number, iceCandidates: RTCIceCandidateInit[], ext: Calls.ExtensionParams = {}): void {
    const extension = Object.assign({}, ext, { iceCandidates });

    this.sendMessage(userID, extension, CallSignalingType.CANDIDATE);
  }

  public sendMessage(userID: number, ext: Calls.ExtensionParams, signalingType: CallSignalingType): void {
    const extension = Object.assign({}, ext);
    /** basic parameters */
    extension.moduleIdentifier = CallSignalingModuleIdentifier;
    extension.signalType = signalingType;
    /** extension.sessionID */
    /** extension.callType */
    extension.platform = Utils.env.isBrowser ? 'web' : Utils.env.isReactNative || Utils.env.isExpo ? 'mobile' : 'node';
    /** extension.callerID */
    /** extension.opponentsIDs */
    /** extension.sdp */

    if (extension.userInfo) {
      if (!Object.keys(extension.userInfo).length) {
        delete extension.userInfo;
      } else if (extension.userInfo.maxBandwidth === 0) {
        delete extension.userInfo.maxBandwidth;
      }
    }

    let messageStanza = ChatUtils.createMessageStanza({
      to: WebRTCHelpers.getUserJid(userID),
      type: 'headline',
      id: Utils.getBsonObjectId(),
    }).c('extraParams', { xmlns: 'jabber:client' });

    Object.keys(extension).forEach((name) => {
      const item = extension[name];

      if (name === 'iceCandidates') {
        messageStanza = messageStanza.c('iceCandidates');
        item.forEach((candidate: any) => {
          messageStanza = messageStanza.c('iceCandidate');
          Object.keys(candidate).forEach((key: string) => {
            messageStanza = messageStanza.c(key).t(candidate[+key]).up();
          });
          messageStanza = messageStanza.up();
        });
        messageStanza = messageStanza.up();
      } else if (name === 'opponentsIDs') {
        messageStanza = messageStanza.c('opponentsIDs');
        item.forEach((opponentId: string) => {
          messageStanza = messageStanza.c('opponentID').t(opponentId).up();
        });
        messageStanza = messageStanza.up();
      } else if (typeof item === 'object') {
        ChatUtils.assignObjectToXml(messageStanza, item, name);
      } else {
        messageStanza = messageStanza.c(name).t(item).up();
      }
    });

    messageStanza = messageStanza.up();

    this.signalingConnection.sendOnline(messageStanza);
  }
}
