import { isBrowser, isExpo, isNodeJS, isReactNative } from './platform';
import config from './config';
import { Config } from './types';

export default class Utils {
  static loggers: Array<(args: any[]) => void> = [];

  static env = {
    isReactNative,
    isBrowser,
    isNodeJS,
    isExpo,
  };

  static safeCallbackCall(cb: (...args: any[]) => void = () => {}): (...args: any[]) => void {
    return (...args: any[]) => {
      if (typeof cb === 'function') {
        try {
          cb(...args);
        } catch (err) {
          console.error('Error in listener:', err, `Check the code:\n>>>\n${cb.toString()}\n<<<`);
        }
      }
    };
  }

  static getUrl(base: Config.ApiRoutes[keyof Config.ApiRoutes], res?: string | number, ext?: string | number): string {
    const resource = res ? `/${res}` : '';
    const extension = ext ? `/${ext}` : '';
    return `https://${config.endpoints.api}/${base}${resource}${extension}${config.urls.type}`;
  }

  static isArray(arg: any): arg is any[] {
    return Array.isArray(arg);
  }

  static isObject(obj: any): boolean {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  static getBsonObjectId(): string {
    // The object for type MongoDB.Bson.ObjectId
    // http://docs.mongodb.org/manual/reference/object-id/
    const ObjectId = {
      machine: Math.floor(Math.random() * 16777216).toString(16),
      pid: Math.floor(Math.random() * 32767).toString(16),
      increment: 0,
    };
    const timestamp = Math.floor(Date.now() / 1000).toString(16);
    const increment = (ObjectId.increment++).toString(16);

    if (parseInt(increment, 16) > 0xffffff) {
      ObjectId.increment = 0;
    }

    return (
      timestamp.padStart(8, '0') +
      ObjectId.machine.padStart(6, '0') +
      ObjectId.pid.padStart(4, '0') +
      increment.padStart(6, '0')
    );
  }

  static DLog(...args: any[]): void {
    if (Utils.loggers.length > 0) {
      Utils.loggers.forEach((logger) => logger(args));
      return;
    }

    const consoleLoggerFunction = () => {
      return (args: any[]) => {
        console.log(...args);
      };
    };

    if (typeof config.debug === 'object') {
      const modes = Array.isArray(config.debug.mode) ? config.debug.mode : [config.debug.mode];
      modes.forEach((mode) => {
        if (mode === 1) {
          Utils.loggers.push(consoleLoggerFunction());
        }
      });
    }

    Utils.loggers.forEach((logger) => logger(args));
  }

  static callTrafficUsageCallback(callbackName: 'xmppDataRead' | 'xmppDataWrite', data: any): void {
    if (typeof config.on[callbackName] === 'function') {
      const getStringSize = (str: string): number => new Blob([str]).size;
      const getDataSize = (data: any = {}): number => {
        const { body: b, headers: h } = data;
        let size = 0;
        if (b) {
          size += getStringSize(typeof b === 'string' ? b : JSON.stringify(b));
        }
        if (h) {
          size += getStringSize(JSON.stringify(h));
        }
        return size;
      };

      config.on[callbackName](getDataSize(data));
    }
  }

  static cloneObject<T>(obj: T = {} as T, escapeNull: boolean = false): T {
    try {
      const json = JSON.stringify(obj);
      const jsonObject = escapeNull ? json.replace(/null/g, '""') : json;

      return JSON.parse(jsonObject);
    } catch {
      return obj;
    }
  }
}
