import { mediaDevices } from '../platform';
import config from '../config';
import WebRTCPeerConnection from './WebRTCPeerConnection';
import WebRTCSignalingProvider from './WebRTCSignalingProvider';
import WebRTCHelpers from './WebRTCHelpers';
import Utils from '../Utils';
import {
  Calls,
  CallSessionConnectionState,
  CallSessionState,
  CallSignalingType,
  CallType,
  Media,
  PeerConnectionState,
} from '../types';

export default class WebRTCSession {
  public ID: string | null = null;
  public state: CallSessionState = CallSessionState.NEW;
  public callType!: CallType;
  public initiatorID!: number;
  public currentUserID!: number;
  public opponentsIDs: number[] = [];
  public maxBandwidth: number = 0;
  public peerConnections: { [userID: number]: WebRTCPeerConnection } = {};
  public localStream?: MediaStream;
  public mediaParams: MediaStreamConstraints = {};
  public signalingProvider!: WebRTCSignalingProvider;
  public answerTimer: NodeJS.Timeout | null = null;
  public waitingOfferOrAnswerTimer: NodeJS.Timeout | null = null;
  public startCallDate!: Date;
  public acceptCallDate!: Date;
  public onUserNotAnswerListener!: Calls.OnUserNotAnswerListener;
  public onRemoteStreamListener!: Calls.OnRemoteStreamListener;
  public onSessionCloseListener!: Calls.OnSessionCloseListener;
  public onCallStatsReportListener!: Calls.OnCallStatsReportListener;
  public onSessionConnectionStateChangedListener!: Calls.OnSessionConnectionStateChangedListener;

  constructor(params: Calls.WebRTCSessionParams) {
    Object.assign(this, params, { ID: params.ID ?? generateUUID() });
  }

  public async getDisplayMedia(params: Media.DisplayParams): Promise<MediaStream> {
    if (!mediaDevices.getDisplayMedia) {
      throw new Error("Your environment does not support 'getDisplayMedia' API");
    }

    const elementId = params && params.elementId;
    const options = params && params.options;
    const mediaParams = { ...params };

    this.mediaParams = mediaParams;

    delete mediaParams.elementId;
    delete mediaParams.options;

    try {
      const stream = await mediaDevices.getDisplayMedia(mediaParams);
      const localStream = this.upsertStream(stream, elementId, options);
      return localStream;
    } catch (error) {
      throw error;
    }
  }

  public async getUserMedia(params: Media.UserParams): Promise<MediaStream> {
    const elementId = params && params.elementId;
    const options = params && params.options;
    const mediaParams = { ...params };

    this.mediaParams = mediaParams;

    delete mediaParams.elementId;
    delete mediaParams.options;

    try {
      const stream = await mediaDevices.getUserMedia(mediaParams);
      const localStream = this.upsertStream(stream, elementId, options);
      return localStream;
    } catch (error) {
      throw error;
    }
  }

  private upsertStream(stream: MediaStream, elementId?: string, options?: Media.ElementOptions): MediaStream {
    const shouldUpdateCurrentStream = !!this.localStream;

    if (shouldUpdateCurrentStream) {
      this.replaceTracks(stream);
    } else {
      this.localStream = stream;
    }

    if (!this.localStream) {
      throw new Error('Local stream is not defined');
    }

    if (elementId) {
      if (shouldUpdateCurrentStream) {
        this.detachMediaStream(elementId, options);
      }
      this.attachMediaStream(elementId, this.localStream, options);
    }

    return this.localStream;
  }

  private replaceTracks(stream: MediaStream): MediaStream {
    this.localStream?.getTracks().forEach((localTrack) => {
      if (localTrack.kind === 'audio' && stream.getAudioTracks().length === 0) {
        return;
      } else {
        localTrack.stop();
        this.localStream?.removeTrack(localTrack);
      }
    });

    stream.getTracks().forEach((newTrack) => {
      if (newTrack.kind === 'audio') {
        newTrack.enabled = this.localStream?.getAudioTracks().every(({ enabled }) => enabled) ?? true;
      } else {
        newTrack.enabled = this.localStream?.getVideoTracks().every(({ enabled }) => enabled) ?? true;
      }

      if (newTrack) {
        Object.values(this.peerConnections).forEach((pc) => {
          pc.getSenders()
            .find(({ track }) => newTrack.kind === track?.kind)
            ?.replaceTrack(newTrack);
        });
        this.localStream?.addTrack(newTrack);
      }
    });

    if (!this.localStream) {
      throw new Error('Local stream is not defined');
    }

    return this.localStream;
  }

  public async setMaxBandwidth(maxBandwidth: number): Promise<any> {
    const peers = this.peerConnections;
    const peersKeys = Object.keys(peers);

    if (peersKeys.length < 1) {
      WebRTCHelpers.trace("No 'RTCPeerConnection' to set 'maxBandwidth'");
      return;
    }

    return Promise.all(peersKeys.map((userID) => peers[Number(userID)].setRTCRtpSenderMaxBandwidth(maxBandwidth)));
  }

  public connectionStateForUser(userID: number): PeerConnectionState | null {
    const peerConnection = this.peerConnections[userID];
    return peerConnection ? peerConnection.state : null;
  }

  public attachMediaStream(elementId: string, stream: MediaStream, opt?: Media.ElementOptions): void {
    const mediaElement = document.getElementById(elementId) as HTMLMediaElement;

    if ('srcObject' in mediaElement) {
      mediaElement.srcObject = stream;
      mediaElement.style.transform = opt?.mirror ? 'scaleX(-1)' : 'none';
      if (typeof opt?.muted === 'boolean') {
        mediaElement.muted = opt?.muted;
      }
      mediaElement.onloadedmetadata = (_event: Event) => {
        mediaElement.play();
      };
    } else {
      throw new Error(`Unable to attach media stream, element #${elementId} is undefined`);
    }
  }

  public detachMediaStream(elementId: string, opt?: Media.ElementOptions): void {
    const mediaElement = document.getElementById(elementId) as HTMLMediaElement;

    if ('srcObject' in mediaElement) {
      mediaElement.pause();
      mediaElement.srcObject = null;
      mediaElement.style.transform = opt?.mirror ? 'scaleX(-1)' : 'none';
      if (typeof opt?.muted === 'boolean') {
        mediaElement.muted = opt?.muted;
      }
    } else {
      throw new Error(`Unable to attach media stream, element #${elementId} is undefined`);
    }
  }

  public async switchMediaTracks(options: Media.TrackConstraintsOrDeviceIds = {}): Promise<MediaStream> {
    Utils.DLog('switchMediaTracks:', options);

    ['audio', 'video'].forEach((type) => {
      const device = type === 'audio' ? options.audio : type === 'audio' ? options.video : {};
      const deviceId = typeof device === 'string' ? device : device?.deviceId;

      if (deviceId) {
        if (typeof this.mediaParams[type] !== 'object') {
          this.mediaParams[type].deviceId = deviceId;
        } else {
          this.mediaParams[type] = { deviceId };
        }
      }
    });

    try {
      const constrains = { audio: this.mediaParams.audio || false, video: this.mediaParams.video || false };
      const stream = await mediaDevices.getUserMedia(constrains);
      const localStream = this.replaceTracks(stream);
      return localStream;
    } catch (error) {
      throw error;
    }
  }

  public call(extension: Calls.UserInfo = {}): void {
    const ext = prepareExtension(extension);

    WebRTCHelpers.trace(`Call, extension: ${JSON.stringify(ext)}`);

    this.state = CallSessionState.ACTIVE;
    this.maxBandwidth = Number(ext.userInfo?.maxBandwidth ?? 0);
    this.opponentsIDs.forEach((userID) => {
      this.callInternal(userID, ext, true);
    });
  }

  private async callInternal(
    userID: number,
    extension: Calls.ExtensionParams,
    withOnNotAnswerCallback: boolean
  ): Promise<void> {
    const pc = new WebRTCPeerConnection(this, userID, 'offer');

    this.localStream?.getTracks().forEach((track) => {
      if (this.localStream) {
        pc.addTrack(track, this.localStream);
      }
    });
    this.peerConnections[userID] = pc;

    try {
      await pc.getAndSetLocalSessionDescription(this.maxBandwidth);
      pc.startDialingTimer(extension, withOnNotAnswerCallback); // call requests to user
      WebRTCHelpers.trace('getAndSetLocalSessionDescription success');
    } catch (error) {
      WebRTCHelpers.traceError(`getAndSetLocalSessionDescription error: ${error}`);
    }
  }

  public accept(extension: Calls.UserInfo = {}): void {
    const ext = prepareExtension(extension);

    WebRTCHelpers.trace(`Accept, extension: ${JSON.stringify(ext)}`);

    if (this.state === CallSessionState.ACTIVE) {
      WebRTCHelpers.traceError("Can't accept, the session is already active, return");
      return;
    }

    if (this.state === CallSessionState.CLOSED) {
      WebRTCHelpers.traceError("Can't accept, the session is already closed, return");
      this.stop({});
      return;
    }

    this.state = CallSessionState.ACTIVE;
    this.acceptCallTime = new Date();
    this.clearAnswerTimer();
    this.acceptInternal(this.initiatorID, ext);

    // group call
    const opponentsIDs = this.opponentsIDs.filter((userID) => userID !== this.initiatorID);

    if (opponentsIDs.length > 0) {
      this.startWaitingOfferOrAnswerTimer();
      opponentsIDs.forEach((opponentID) => {
        if (this.currentUserID > opponentID) {
          this.callInternal(opponentID, extension, true);
        }
      });
    }
  }

  private async acceptInternal(userID: number, extension: Calls.ExtensionParams): Promise<void> {
    const pc = this.peerConnections[userID];

    if (!pc || !pc.sdpRemote) {
      WebRTCHelpers.traceError("Can't accept the call, there is no information about peer connection by some reason");
      return;
    }

    this.localStream?.getTracks().forEach((track) => {
      if (this.localStream) {
        pc.addTrack(track, this.localStream);
      }
    });

    try {
      await pc.setRemoteSessionDescription('offer', pc.sdpRemote);
      await pc.getAndSetLocalSessionDescription(this.maxBandwidth);

      const ext = Object.assign({}, extension, {
        sessionID: this.ID,
        callType: this.callType,
        callerID: this.initiatorID,
        opponentsIDs: this.opponentsIDs,
        sdp: pc.localDescription.sdp,
      });

      this.signalingProvider.sendMessage(userID, ext, CallSignalingType.ACCEPT);
    } catch (error) {
      WebRTCHelpers.traceError(`[acceptInternal] Error: ${error}`);
    }
  }

  public reject(extension: Calls.UserInfo = {}): void {
    const ext = prepareExtension(extension);

    WebRTCHelpers.trace(`Reject, extension: ${JSON.stringify(ext.userInfo)}`);

    this.state = CallSessionState.REJECTED;
    this.clearAnswerTimer();
    Object.assign(ext, {
      sessionID: this.ID,
      callType: this.callType,
      callerID: this.initiatorID,
      opponentsIDs: this.opponentsIDs,
    });

    Object.keys(this.peerConnections).forEach((userID) => {
      this.signalingProvider.sendMessage(Number(userID), ext, CallSignalingType.REJECT);
    });

    this.close();
  }

  public stop(extension: Calls.UserInfo = {}): void {
    const ext = prepareExtension(extension);

    WebRTCHelpers.trace(`Stop, extension: ${JSON.stringify(ext.userInfo)}`);

    this.state = CallSessionState.HUNGUP;

    if (this.answerTimer) {
      this.clearAnswerTimer();
    }

    Object.assign(ext, {
      sessionID: this.ID,
      callType: this.callType,
      callerID: this.initiatorID,
      opponentsIDs: this.opponentsIDs,
    });

    Object.keys(this.peerConnections).forEach((userID) => {
      this.signalingProvider.sendMessage(Number(userID), ext, CallSignalingType.STOP);
    });

    this.close();
  }

  public canInitiateIceRestart(userID: number): boolean {
    return this.peerConnections[userID]?.type === 'offer';
  }

  public async iceRestart(userID: number): Promise<void> {
    const pc = this.peerConnections[userID];

    if (!pc) {
      WebRTCHelpers.traceError("Can't restart ice, there is no information about peer connection by some reason");
      return;
    }

    try {
      const { sdp } = await pc.getAndSetLocalSessionDescription(this.maxBandwidth, true);
      const ext = { sessionID: this.ID ?? '', sdp };
      this.signalingProvider.sendMessage(userID, ext, CallSignalingType.RESTART);
      WebRTCHelpers.trace('[iceRestart] OK');
    } catch (error) {
      WebRTCHelpers.traceError(`[iceRestart] Error: ${error}`);
    }
  }

  public processOnCall(callerID: number, extension: Calls.ExtensionParams): void {
    const opponentsIDs = [this.initiatorID, ...this.opponentsIDs.filter((userID) => userID !== this.currentUserID)];

    opponentsIDs.forEach((opponentID) => {
      const pc = this.peerConnections[opponentID];

      if (pc) {
        if (opponentID === callerID) {
          pc.sdpRemote = extension.sdp;
          /** The group call logic starts here */
          if (callerID !== this.initiatorID && this.state === CallSessionState.ACTIVE) {
            this.acceptInternal(callerID, extension);
          }
        }
      } else {
        /** create peer connections for each opponent */
        const rtcSdpType = opponentID !== callerID && this.currentUserID > opponentID ? 'offer' : 'answer';
        const pc = new WebRTCPeerConnection(this, opponentID, rtcSdpType);

        if (opponentID === callerID) {
          pc.sdpRemote = extension.sdp;
          this.startAnswerTimer();
        }

        this.peerConnections[opponentID] = pc;
      }
    });
  }

  public async processOnAccept(userID: number, extension: Calls.ExtensionParams): Promise<void> {
    const pc = this.peerConnections[userID];

    if (!pc) {
      WebRTCHelpers.traceWarning("Ignore 'OnAccept', there is no information about peer connection by some reason");
    }

    try {
      pc.clearDialingTimer();
      await pc.setRemoteSessionDescription('answer', extension.sdp);
      WebRTCHelpers.trace("'setRemoteSessionDescription' success");
    } catch (error) {
      WebRTCHelpers.traceError(`'setRemoteSessionDescription' error: ${error}`);
    }
  }

  public processOnReject(userID: number): void {
    const pc = this.peerConnections[userID];

    this.clearWaitingOfferOrAnswerTimer();

    if (pc) {
      pc.release();
    } else {
      WebRTCHelpers.traceWarning("Ignore 'OnReject', there is no information about peer connection by some reason");
    }

    this.closeSessionIfAllConnectionsClosed();
  }

  public processOnStop(userID: number): void {
    this.clearAnswerTimer();

    /** drop the call if the initiator did it */
    if (userID === this.initiatorID) {
      const pcKeys = Object.keys(this.peerConnections);
      if (pcKeys.length > 0) {
        pcKeys.forEach((key: string) => {
          this.peerConnections[Number(key)].release();
        });
      } else {
        WebRTCHelpers.traceWarning("Ignore 'OnStop', there is no information about peer connections by some reason");
      }
    } else {
      const pc = this.peerConnections[userID];
      if (pc) {
        pc.release();
      } else {
        WebRTCHelpers.traceWarning('Ignore "OnStop", there is no information about peer connection by some reason');
      }
    }

    this.closeSessionIfAllConnectionsClosed();
  }

  public async processOnIceCandidates(userID: number, extension: Calls.ExtensionParams): Promise<void> {
    const pc = this.peerConnections[userID];

    if (!pc) {
      WebRTCHelpers.traceWarning(
        'Ignore "OnIceCandidates", there is no information about peer connection by some reason'
      );
    }

    if (extension.iceCandidates) {
      await pc.addCandidates(extension.iceCandidates);
    }
  }

  public async processOnIceRestart(userID: number, extension: Calls.ExtensionParams): Promise<void> {
    const pc = this.peerConnections[userID];

    if (!pc) {
      WebRTCHelpers.traceWarning('Ignore "OnIceRestart", there is no information about peer connection by some reason');
    }

    try {
      await pc.setRemoteSessionDescription('offer', extension.sdp);
      const { sdp } = await pc.getAndSetLocalSessionDescription(this.maxBandwidth);
      const ext = { sessionID: this.ID ?? '', sdp };
      this.signalingProvider.sendMessage(userID, ext, CallSignalingType.RESTART_ACCEPT);
      WebRTCHelpers.trace('[processOnIceRestart] Success');
    } catch (error) {
      WebRTCHelpers.traceError(`[processOnIceRestart] Error: ${error}`);
    }
  }

  public async processOnIceRestartAccept(userID: number, extension: Calls.ExtensionParams): Promise<void> {
    const pc = this.peerConnections[userID];

    if (!pc) {
      WebRTCHelpers.traceWarning(
        'Ignore "OnIceRestartAccept", there is no information about peer connection by some reason'
      );
    }

    try {
      await pc.setRemoteSessionDescription('answer', extension.sdp);
      WebRTCHelpers.trace('[processOnIceRestartAccept] Success');
    } catch (error) {
      WebRTCHelpers.traceError(`[processOnIceRestartAccept] Error: ${error}`);
    }
  }

  public processCall(peerConnection: WebRTCPeerConnection, extension: Calls.ExtensionParams = {}): void {
    const ext = Object.assign({ userInfo: {} }, extension, {
      sessionID: this.ID,
      callType: this.callType,
      callerID: this.initiatorID,
      opponentsIDs: this.opponentsIDs,
      sdp: peerConnection.localDescription.sdp,
    });

    this.signalingProvider.sendMessage(peerConnection.userID, ext, CallSignalingType.CALL);
  }

  public processIceCandidates(userID: number, iceCandidates: RTCIceCandidateInit[]): void {
    const extension = {
      sessionID: this.ID ?? '',
      callType: this.callType,
      callerID: this.initiatorID,
      opponentsIDs: this.opponentsIDs,
    };

    this.signalingProvider.sendCandidate(userID, iceCandidates, extension);
  }

  public processOnNotAnswer(peerConnection: WebRTCPeerConnection): void {
    WebRTCHelpers.trace(`Answer timeout callback for session ${this.ID} for user ${peerConnection.userID}`);

    this.clearWaitingOfferOrAnswerTimer();
    peerConnection.release();
    Utils.safeCallbackCall(this.onUserNotAnswerListener)(this, peerConnection.userID);
    this.closeSessionIfAllConnectionsClosed();
  }

  public onRemoteStream(userID: number, stream: MediaStream): void {
    Utils.safeCallbackCall(this.onRemoteStreamListener)(this, userID, stream);
  }

  public onCallStatsReport(userID: number, stats: any, error?: Error): void {
    Utils.safeCallbackCall(this.onCallStatsReportListener)(this, userID, stats, error);
  }

  public onSessionConnectionStateChanged(userID: number, connectionState: CallSessionConnectionState): void {
    Utils.safeCallbackCall(this.onSessionConnectionStateChangedListener)(this, userID, connectionState);
  }

  private close(): void {
    Object.values(this.peerConnections).forEach((pc) => {
      try {
        pc.release();
      } catch (e) {
        Utils.DLog(`Peer close error: ${e}`);
      }
    });

    this.closeLocalMediaStream();
    this.state = CallSessionState.CLOSED;
    Utils.safeCallbackCall(this.onSessionCloseListener)(this);
  }

  public closeSessionIfAllConnectionsClosed(): void {
    let isAllClosed = true;

    Object.values(this.peerConnections).forEach((pc) => {
      let isClosed = false;
      try {
        /*
         * TODO:
         * Uses RTCPeerConnection.signalingState instead RTCPeerConnection.iceConnectionState,
         * because state 'closed' comes after few time from Safari, but signaling state comes instantly
         */
        isClosed = pc.iceConnectionState === 'closed' || pc.signalingState === 'closed' || pc.released;
      } catch (err) {
        WebRTCHelpers.traceError(err);
        // need to set peerState to 'closed' on error. FF will crashed without this part.
        isClosed = true;
      }

      isAllClosed = isClosed;
    });

    WebRTCHelpers.trace(`All peer connections closed: ${isAllClosed}`);

    if (isAllClosed) {
      this.closeLocalMediaStream();
      Utils.safeCallbackCall(this.onSessionCloseListener)(this);
      this.state = CallSessionState.CLOSED;
    }
  }

  private closeLocalMediaStream(): void {
    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => track.stop());
      this.localStream = undefined;
      this.mediaParams = {};
    }
  }

  public mute(type: 'audio' | 'video'): void {
    this.muteStream(false, type);
  }

  public unmute(type: 'audio' | 'video'): void {
    this.muteStream(true, type);
  }

  private muteStream(enabled: boolean, type: 'audio' | 'video' | 'none' = 'none'): void {
    const tracks =
      type === 'audio'
        ? this.localStream?.getAudioTracks()
        : type === 'video'
        ? this.localStream?.getVideoTracks()
        : [];

    tracks?.forEach((track) => {
      track.enabled = enabled;
    });
  }

  private clearAnswerTimer(): void {
    if (this.answerTimer) {
      WebRTCHelpers.trace('clearAnswerTimer');
      clearTimeout(this.answerTimer);
      this.answerTimer = null;
    }
  }

  private startAnswerTimer(): void {
    WebRTCHelpers.trace('startAnswerTimer');

    this.answerTimer = setTimeout(() => {
      WebRTCHelpers.trace('answerTimeoutCallback');
      this.close();
      this.answerTimer = null;
    }, config.videochat.answerTimeInterval * 1000);
  }

  private clearWaitingOfferOrAnswerTimer(): void {
    if (this.waitingOfferOrAnswerTimer) {
      WebRTCHelpers.trace('clearWaitingOfferOrAnswerTimer');
      clearTimeout(this.waitingOfferOrAnswerTimer);
      this.waitingOfferOrAnswerTimer = null;
    }
  }

  private startWaitingOfferOrAnswerTimer(): void {
    const timeDiff = (this.acceptCallTime - this.startCallTime) / 1000;
    const timeout = Math.max(config.videochat.answerTimeInterval - timeDiff, 1);

    WebRTCHelpers.trace(`startWaitingOfferOrAnswerTimer, timeout: ${timeout}`);

    this.waitingOfferOrAnswerTimer = setTimeout(() => {
      WebRTCHelpers.trace('waitingOfferOrAnswerTimeoutCallback');

      Object.values(this.peerConnections).forEach((pc) => {
        if (pc.state === PeerConnectionState.CONNECTING || pc.state === PeerConnectionState.NEW) {
          this.processOnNotAnswer(pc);
        }
      });

      this.waitingOfferOrAnswerTimer = null;
    }, timeout * 1000);
  }

  public toString(): string {
    return `ID: ${this.ID}, initiatorID: ${this.initiatorID}, opponentsIDs: ${this.opponentsIDs}, state: ${this.state}, callType: ${this.callType}`;
  }

  set startCallTime(date: Date) {
    this.startCallDate = date;
  }

  get startCallTime(): number {
    return this.startCallDate.getTime();
  }

  set acceptCallTime(date: Date) {
    this.acceptCallDate = date;
  }

  get acceptCallTime(): number {
    return this.acceptCallDate.getTime();
  }
}

function generateUUID(): string {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

function prepareExtension(extension: Calls.UserInfo = {}): Calls.ExtensionParams {
  const ext = { userInfo: extension };

  try {
    if (Utils.isObject(extension)) {
      ext.userInfo = Utils.cloneObject(extension, true);
    } else {
      WebRTCHelpers.traceWarning('Ignore "prepareExtension", must be an object');
    }
  } catch (err) {
    WebRTCHelpers.traceWarning(err.message);
  }

  return ext;
}
