import { mediaDevices } from '../platform';
import ConferenceSession from './ConferenceSession';
import ProxyService from '../Proxy';
import { Janus, JanusCallType, ConferenceEvent, DeviceInputType } from '../types';

export default class ConferenceClient {
  public proxy: ProxyService;
  public DeviceInputType: typeof DeviceInputType = DeviceInputType;
  public JanusCallType: typeof JanusCallType = JanusCallType;
  public sessionsStore: { [id: ConferenceSession['id']]: ConferenceSession } = {};
  public onParticipantJoinedListener!: Janus.OnParticipantJoinedListener;
  public onParticipantLeftListener!: Janus.OnParticipantLeftListener;
  public onSlowLinkListener!: Janus.OnSlowLinkListener;
  public onRemoteStreamListener!: Janus.OnRemoteStreamListener;
  public onRemoteTracksUpdatedListener!: Janus.OnRemoteTracksUpdatedListener;
  public onRemoteConnectionStateChangedListener!: Janus.OnRemoteConnectionStateChangedListener;
  public onDataChannelOpenedListener!: Janus.OnDataChannelOpenedListener;
  public onDataChannelMessageListener!: Janus.OnDataChannelMessageListener;
  public onSessionConnectionStateChangedListener!: Janus.OnSessionConnectionStateChangedListener;
  public onErrorListener!: Janus.OnErrorListener;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  createNewSession() {
    const session = new ConferenceSession(this.getCurrentSessionToken());

    this.sessionsStore[session.id] = session;
    session.onParticipantJoinedListener = this.onParticipantJoinedListener;
    session.onParticipantLeftListener = this.onParticipantLeftListener;
    session.onSlowLinkListener = this.onSlowLinkListener;
    session.onRemoteStreamListener = this.onRemoteStreamListener;
    session.onRemoteTracksUpdatedListener = this.onRemoteTracksUpdatedListener;
    session.onRemoteConnectionStateChangedListener = this.onRemoteConnectionStateChangedListener;
    session.onDataChannelOpenedListener = this.onDataChannelOpenedListener;
    session.onDataChannelMessageListener = this.onDataChannelMessageListener;
    session.onSessionConnectionStateChangedListener = this.onSessionConnectionStateChangedListener;
    session.onErrorListener = this.onErrorListener;

    return session;
  }

  public async getMediaDevices(kind?: DeviceInputType): Promise<MediaDeviceInfo[]> {
    if (mediaDevices?.enumerateDevices) {
      const devices = await mediaDevices.enumerateDevices();
      return kind ? devices.filter((device) => device.kind === kind) : devices;
    } else {
      throw new Error("No 'enumerateDevices' API supported");
    }
  }

  public clearSession(session_id: ConferenceSession['id']): void {
    delete this.sessionsStore[session_id];
  }

  public getCurrentSessionToken(): string {
    const currentSession = this.proxy.getSession();

    if (currentSession?.token) {
      return currentSession.token;
    } else {
      throw new Error('Session does not exist');
    }
  }

  private getListenerByName(name: ConferenceEvent): Janus.ListenerName | null {
    switch (name) {
      case ConferenceEvent.JOIN:
        return 'onParticipantJoinedListener';
      case ConferenceEvent.LEFT:
        return 'onParticipantLeftListener';
      case ConferenceEvent.SLOW_LINK:
        return 'onSlowLinkListener';
      case ConferenceEvent.REMOTE_STREAM:
        return 'onRemoteStreamListener';
      case ConferenceEvent.REMOTE_TRACKS_UPDATED:
        return 'onRemoteTracksUpdatedListener';
      case ConferenceEvent.REMOTE_CONNECTION_STATE:
        return 'onRemoteConnectionStateChangedListener';
      case ConferenceEvent.DATA_CHANNEL_OPENED:
        return 'onDataChannelOpenedListener';
      case ConferenceEvent.DATA_CHANNEL_MESSAGE:
        return 'onDataChannelMessageListener';
      case ConferenceEvent.SESSION_CONNECTION_STATE:
        return 'onSessionConnectionStateChangedListener';
      case ConferenceEvent.ERROR:
        return 'onErrorListener';
      default:
        return null;
    }
  }

  public addListener(name: ConferenceEvent, listener: Janus.Listeners): () => void {
    const listenerName = this.getListenerByName(name);
    if (listenerName) {
      (this[listenerName] as Janus.Listeners) = listener;
    }
    return this.removeListener.bind(this, name);
  }

  public removeListener(name: ConferenceEvent): void {
    const listenerName = this.getListenerByName(name);
    if (listenerName) {
      (this[listenerName] as Janus.Listeners | undefined) = undefined;
    }
  }

  public removeAllListeners(): void {
    Object.keys(this).forEach((key) => {
      if (key.startsWith('on') && key.endsWith('Listener') && typeof this[key as Janus.ListenerName] === 'function') {
        (this[key as Janus.ListenerName] as Janus.Listeners | undefined) = undefined;
      }
    });
  }
}
