import WebRTCClient from './WebRTCClient';
import ChatHelpers from '../messaging/ChatUtils';
import ChatUtils from '../messaging/ChatUtils';
import { Calls, CallSignalingType, CallType, Chat } from '../types';

export default class WebRTCSignalingProcessor {
  public delegate: WebRTCClient;

  constructor(delegate: WebRTCClient) {
    this.delegate = delegate;
  }

  public onMessage = (userID: number, extraParams: Chat.XmlElement): void => {
    const extension = this.getExtension(extraParams);
    const sessionId = extension?.sessionID ?? '';
    const signalType = extension?.signalType;
    /** cleanup */
    delete extension?.moduleIdentifier;
    delete extension?.sessionID;
    delete extension?.signalType;

    switch (signalType) {
      case CallSignalingType.CALL:
        this.delegate.onCallHandler(userID, sessionId, extension);
        break;
      case CallSignalingType.ACCEPT:
        this.delegate.onAcceptHandler(userID, sessionId, extension);
        break;
      case CallSignalingType.REJECT:
        this.delegate.onRejectHandler(userID, sessionId, extension);
        break;
      case CallSignalingType.STOP:
        this.delegate.onStopHandler(userID, sessionId, extension);
        break;
      case CallSignalingType.CANDIDATE:
        this.delegate.onIceCandidatesHandler(userID, sessionId, extension);
        break;
      case CallSignalingType.RESTART:
        this.delegate.onIceRestartHandler(userID, sessionId, extension);
        break;
      case CallSignalingType.RESTART_ACCEPT:
        this.delegate.onIceRestartAcceptHandler(userID, sessionId, extension);
        break;
    }
  };

  private getExtension(extraParams?: Chat.XmlElement): Calls.ExtensionParams {
    if (!extraParams) {
      return {};
    }

    const extension: Calls.ExtensionParams = { iceCandidates: [], opponentsIDs: [] };
    const elements = ChatUtils.getChildElements(extraParams);

    elements.forEach((element: Chat.XmlElement) => {
      const items = ChatUtils.getChildElements(element);
      const key = ChatUtils.getName(element) ?? '';

      switch (key) {
        case 'callerID':
          extension[key] = parseInt(ChatUtils.getText(element) || '0');
          break;

        case 'callType':
          const type = ChatUtils.getText(element);
          extension[key] = type === '1' ? CallType.VIDEO : type === '2' ? CallType.AUDIO : undefined;
          break;

        case 'iceCandidates':
          items.forEach((item) => {
            const candidate = {};
            ChatUtils.getChildElements(item).forEach((child) => {
              const childName = ChatUtils.getName(child) || '';
              const childText = ChatUtils.getText(child);
              candidate[childName] = childName === 'sdpMLineIndex' ? parseInt(childText || '0') : childText;
            });
            extension.iceCandidates?.push(candidate);
          });
          break;

        case 'opponentsIDs':
          items.forEach((item) => {
            extension.opponentsIDs?.push(parseInt(ChatUtils.getText(item) || '0'));
          });
          break;

        default:
          if (items.length > 1) {
            if ((ChatUtils.getText(element) || '').length > 4096) {
              extension[key] = items.reduce((acc, item) => acc + ChatUtils.getText(item), '');
            } else {
              ChatHelpers.assignXmlToObject(element, extension);
            }
          } else {
            if (key === 'userInfo') {
              ChatHelpers.assignXmlToObject(element, extension);
            } else {
              extension[key] = ChatUtils.getText(element);
            }
          }
          break;
      }
    });

    if (extension.iceCandidates?.length === 0) {
      delete extension.iceCandidates;
    }

    if (extension.opponentsIDs?.length === 0) {
      delete extension.opponentsIDs;
    }

    return extension;
  }
}
