import { Config } from '../config';

export enum ChatProtocol {
  BOSH = 1,
  WS = 2,
}
export enum JanusDebugMode {
  ALL = 'all',
  TRACE = 'trace',
  DEBUG = 'debug',
  VDEBUG = 'vdebug',
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error',
}

export namespace ConnectyCubeConfig {
  export type Object = Partial<Config>;
  export type Credentials = {
    appId: number | string;
    authKey?: string;
    token?: string;
    systemApiKey?: string;
    authSecret?: string;
  };
  export type Endpoints = { api: string; chat: string; muc?: string };
  export type ChatProtocolURLs = { bosh?: string; websocket?: string; active: ChatProtocol | number };
  export type ChatStreamManagement = { enable: boolean };
  export type ChatPing = { enable: boolean; timeInterval: number };
  export type ChatReconnect = { enable: boolean; timeInterval: number };
  export type Chat = {
    streamManagement?: ChatStreamManagement;
    ping?: ChatPing;
    reconnect?: ChatReconnect;
  };
  export type VideochatIceServer = {
    urls: string | string[];
    url?: string;
    credential?: string;
    username?: string;
  };
  export type Videochat = {
    alwaysRelayCalls?: boolean;
    answerTimeInterval?: number;
    dialingTimeInterval?: number;
    disconnectTimeInterval?: number;
    statsReportTimeInterval?: number | null;
    iceServers?: VideochatIceServer[];
  };
  export type JanusDebug = JanusDebugMode.ALL | JanusDebugMode[] | boolean;
  export type Conference = {
    server: string;
    debug?: JanusDebug;
  };
  export type Whiteboard = {
    server: string;
  };
  export type LinkPreview = {
    server: string;
  };
  export type ApiRoutes = {
    session: string;
    login: string;
    users: string;
    chat: string;
    blobs: string;
    subscriptions: string;
    events: string;
    data: string;
    addressbook: string;
    meetings: string;
    whiteboards: string;
    unfurl: string;
    calls: string;
    type: string;
  };
  export type SessionHandlerResponse = () => void;
  export type SessionRetryCallback = (session: any) => void;
  export type SessionExpiredListener = (handler: SessionHandlerResponse, retry: SessionRetryCallback) => void;
  export type XMPPDataListener = (size: number) => number;
  export type Listeners = {
    sessionExpired?: SessionExpiredListener;
    xmppDataWrite?: XMPPDataListener;
    xmppDataRead?: XMPPDataListener;
  };
  export type Debug = { mode: number | boolean };
  export type Options = {
    creds?: Credentials;
    endpoints?: Endpoints;
    chatProtocol?: ChatProtocolURLs;
    chat?: Chat;
    videochat?: Videochat;
    conference?: Conference;
    whiteboard?: Whiteboard;
    on?: Listeners;
    timeout?: number;
    debug?: Debug;
  };
}
