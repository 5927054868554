import { ForceParams } from './common';
import { ConnectyCubeUsers } from './users';

export enum AddressBookUserDataType {
  COMPACT = 1,
  FULL = 0,
}

export namespace ConnectyCubeAddressBook {
  export type Contact = { name: string; phone: string; destroy?: number | string };
  // Request parameters type
  export type UploadParams = { udid?: string } & ForceParams;
  export type GetRegisteredUsersParams = boolean | { udid?: string; compact: AddressBookUserDataType | number }; // 1 - compact user data, 0 - full user data
  // Response data types
  export type UploadResponse = {
    deleted: number;
    rejected: Record<string | number, string[]>;
    created: number;
    updated: number;
  };
  export type GetRegisteredUsersResponse = {
    items: Partial<ConnectyCubeUsers.User> & { address_book_name: string }[];
  };
}
