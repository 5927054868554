import { RTCPeerConnection, RTCSessionDescription, RTCIceCandidate, MediaStream } from '../platform';
import config from '../config';
import WebRTCSession from './WebRTCSession';
import WebRTCHelpers from './WebRTCHelpers';
import { Calls, CallSessionConnectionState, CallType, Config, PeerConnectionState } from '../types';

export default class WebRTCPeerConnection {
  public original!: RTCPeerConnection;
  public session!: WebRTCSession;
  public userID!: number;
  public type!: RTCSdpType;
  public remoteSDP: string | null = null;
  public state: PeerConnectionState = PeerConnectionState.NEW;
  public iceCandidates: RTCIceCandidateInit[] = [];
  public remoteStream: MediaStream = new MediaStream();
  public answerTimeInterval: number = 0;
  public onStatusClosedChecker: NodeJS.Timeout | null = null;
  public dialingTimer: NodeJS.Timeout | null = null;
  public statsReportTimer: NodeJS.Timeout | null = null;
  public waitingReconnectTimeoutCallback: NodeJS.Timeout | null = null;
  public released: boolean = false;

  constructor(session: WebRTCSession, userID: number, type: RTCSdpType) {
    this.create();
    this.setup(session, userID, type);
  }

  private create() {
    const rtcConfiguration: RTCConfiguration = {
      iceTransportPolicy: (config.videochat.alwaysRelayCalls ? 'relay' : 'all') as RTCIceTransportPolicy,
      iceServers: config.videochat.iceServers.map(
        (item: Config.VideochatIceServer): RTCIceServer => ({
          urls: item.urls ?? item.url,
          username: item.username,
          credential: item.credential,
        })
      ) as RTCIceServer[],
    };

    this.original = RTCPeerConnection ? new RTCPeerConnection(rtcConfiguration) : ({} as any);

    WebRTCHelpers.trace(`new RTCPeerConnection(${JSON.stringify(rtcConfiguration, null, 2)})`);
  }

  private setup(session: WebRTCSession, userID: number, type: RTCSdpType): void {
    this.type = type;
    this.userID = userID;
    this.session = session;
    this.session.startCallTime = new Date();

    this.original.ontrack = this.onMediaTrackHandler;
    this.original.onicecandidate = this.onIceCandidateHandler;
    this.original.onsignalingstatechange = this.onSignalingStateHandler;
    this.original.oniceconnectionstatechange = this.onIceConnectionStateHandler;

    WebRTCHelpers.trace(`RTCPeerConnection init. userID: ${userID}, sessionID: ${session.ID}, type: ${type}`);
  }

  private onMediaTrackHandler = (event: RTCTrackEvent): void => {
    this.remoteStream.addTrack(event.track);

    if (
      (this.session.callType == CallType.VIDEO && this.remoteStream.getVideoTracks().length) ||
      (this.session.callType == CallType.AUDIO && this.remoteStream.getAudioTracks().length)
    ) {
      this.session.onRemoteStream(this.userID, this.remoteStream);
    }

    this.getWrappedStats();
  };

  private onIceCandidateHandler = (event: RTCPeerConnectionIceEvent): void => {
    if (event.candidate) {
      const { sdpMLineIndex, sdpMid, candidate } = event.candidate;
      const iceCandidate: RTCIceCandidateInit = { sdpMLineIndex, sdpMid, candidate };

      if (this.signalingState === 'stable') {
        this.session.processIceCandidates(this.userID, [iceCandidate]);
      } else {
        this.iceCandidates.push(iceCandidate);
      }
    }
  };

  private onSignalingStateHandler = (): void => {
    WebRTCHelpers.trace(`onSignalingStateHandler: ${this.signalingState}`);

    if (this.signalingState === 'stable' && this.iceCandidates.length > 0) {
      this.session.processIceCandidates(this.userID, this.iceCandidates);
      this.iceCandidates.length = 0;
    }
  };

  /** handler of remote media stream */
  private onIceConnectionStateHandler = (): void => {
    WebRTCHelpers.trace(`onIceConnectionStateHandler: ${this.iceConnectionState}`);

    if (this.onStatusClosedChecker && WebRTCHelpers.getVersionSafari >= 11) {
      clearTimeout(this.onStatusClosedChecker);
    }

    switch (this.iceConnectionState) {
      case 'checking':
        this.state = PeerConnectionState.CHECKING;
        this.session.onSessionConnectionStateChanged(this.userID, CallSessionConnectionState.CONNECTING);
        break;
      case 'connected':
        this.state = PeerConnectionState.CONNECTED;
        this.clearWaitingReconnectTimer();
        this.session.onSessionConnectionStateChanged(this.userID, CallSessionConnectionState.CONNECTED);
        break;
      case 'completed':
        this.state = PeerConnectionState.COMPLETED;
        this.clearWaitingReconnectTimer();
        this.session.onSessionConnectionStateChanged(this.userID, CallSessionConnectionState.COMPLETED);
        break;
      case 'failed':
        this.state = PeerConnectionState.FAILED;
        this.session.onSessionConnectionStateChanged(this.userID, CallSessionConnectionState.FAILED);
        break;
      case 'disconnected':
        this.state = PeerConnectionState.DISCONNECTED;
        this.startWaitingReconnectTimer();
        this.session.onSessionConnectionStateChanged(this.userID, CallSessionConnectionState.DISCONNECTED);
        if (WebRTCHelpers.getVersionSafari >= 11) {
          this.onStatusClosedChecker = setTimeout(() => {
            this.onIceConnectionStateHandler(); // repeat to get status "closed"
          }, 500);
        }
        break;
      case 'closed': // TODO: this state doesn't fires on Safari 11
        this.state = PeerConnectionState.CLOSED;
        this.clearWaitingReconnectTimer();
        this.session.onSessionConnectionStateChanged(this.userID, CallSessionConnectionState.CLOSED);
        break;
      default:
        break;
    }
  };

  private clearWaitingReconnectTimer(): void {
    if (this.waitingReconnectTimeoutCallback) {
      WebRTCHelpers.trace('clearWaitingReconnectTimer');
      clearTimeout(this.waitingReconnectTimeoutCallback);
      this.waitingReconnectTimeoutCallback = null;
    }
  }

  private startWaitingReconnectTimer(): void {
    const timeout = config.videochat.disconnectTimeInterval * 1000;
    WebRTCHelpers.trace(`startWaitingReconnectTimer, timeout: ${timeout}`);

    this.waitingReconnectTimeoutCallback = setTimeout(() => {
      WebRTCHelpers.trace('waitingReconnectTimeoutCallback');
      if (this.waitingReconnectTimeoutCallback) {
        clearTimeout(this.waitingReconnectTimeoutCallback);
      }
      this.release();
      this.session.closeSessionIfAllConnectionsClosed();
    }, timeout);
  }

  private clearStatsReportTimer(): void {
    if (this.statsReportTimer) {
      clearInterval(this.statsReportTimer);
      this.statsReportTimer = null;
    }
  }

  public async addCandidates(iceCandidates: RTCIceCandidateInit[]): Promise<void[]> {
    const addIceCandidatesPromises = iceCandidates.reduce(
      (promises: Promise<void>[], { sdpMLineIndex, sdpMid, candidate }: RTCIceCandidateInit) => {
        if (candidate) {
          const iceCandidate = new RTCIceCandidate({ sdpMLineIndex, sdpMid, candidate });
          const addIceCandidatePromise = this.addIceCandidate(iceCandidate).catch((error) => {
            WebRTCHelpers.traceError(`Error on 'addIceCandidate': ${error}`);
          });
          promises.push(addIceCandidatePromise);
        }
        return promises;
      },
      []
    );

    return Promise.all(addIceCandidatesPromises);
  }

  public release(): void {
    this.clearDialingTimer();
    this.clearStatsReportTimer();
    this.close();
    if (WebRTCHelpers.getVersionSafari >= 11) {
      this.onIceConnectionStateHandler(); // TODO: 'closed' state doesn't fires on Safari 11 (do it manually)
    }
    this.released = true;
  }

  public clearDialingTimer(): void {
    if (this.dialingTimer) {
      WebRTCHelpers.trace('clearDialingTimer');
      clearInterval(this.dialingTimer);
      this.dialingTimer = null;
      this.answerTimeInterval = 0;
    }
  }

  public async getAndSetLocalSessionDescription(
    maxBandwidth: number,
    iceRestart: boolean = false
  ): Promise<RTCSessionDescriptionInit> {
    this.state = PeerConnectionState.CONNECTING;

    try {
      const offerOptions = iceRestart ? { iceRestart } : undefined;
      const sessionDescription =
        this.type === 'offer' ? await this.createOffer(offerOptions) : await this.createAnswer();

      await this.setLocalDescription(sessionDescription);
      await this.setRTCRtpSenderMaxBandwidth(maxBandwidth);

      return sessionDescription;
    } catch (error) {
      throw error;
    }
  }

  public async setRTCRtpSenderMaxBandwidth(maxBandwidth: number): Promise<any> {
    const senders = this.getSenders() || [];
    const setParamsPromises = senders.reduce((promises: Promise<void>[], sender: RTCRtpSender) => {
      if (sender.track?.kind === 'video') {
        const params = sender.getParameters();

        if (!params.encodings) {
          params.encodings = [];
        }

        if (!maxBandwidth) {
          delete params.encodings[0]?.maxBitrate;
        } else {
          params.encodings[0].maxBitrate = maxBandwidth * 1000;
        }

        promises.push(
          sender
            .setParameters(params)
            .then(() => {
              WebRTCHelpers.trace(`Set maxBandwidth success [${this.userID}]: ${maxBandwidth} kbps`);
            })
            .catch((error) => {
              WebRTCHelpers.trace(`Set maxBandwidth error [${this.userID}]: ${error}`);
            })
        );
      }

      return promises;
    }, []);

    return Promise.all(setParamsPromises);
  }

  public startDialingTimer(extension: Calls.ExtensionParams, withOnNotAnswerCallback: boolean): void {
    const dialingTimeInterval = config.videochat.dialingTimeInterval * 1000;

    WebRTCHelpers.trace(`startDialingTimer, dialingTimeInterval: ${JSON.stringify(dialingTimeInterval)}`);

    const dialingCallback = (extension, withOnNotAnswerCallback, skipIncrement) => {
      if (!skipIncrement) {
        this.answerTimeInterval += config.videochat.dialingTimeInterval * 1000;
      }

      WebRTCHelpers.trace(`dialingCallback, extension: ${JSON.stringify(extension)}`);

      if (this.answerTimeInterval >= config.videochat.answerTimeInterval * 1000) {
        this.clearDialingTimer();

        if (withOnNotAnswerCallback) {
          this.session.processOnNotAnswer(this);
        }
      } else {
        this.session.processCall(this, extension);
      }
    };

    this.dialingTimer = setInterval(dialingCallback, dialingTimeInterval, extension, withOnNotAnswerCallback, false);

    dialingCallback(extension, withOnNotAnswerCallback, true); // call for the 1st time
  }

  public async setRemoteSessionDescription(type: RTCSdpType, sdp: string): Promise<void> {
    const desc = new RTCSessionDescription!({ sdp, type });
    return this.setRemoteDescription(desc);
  }

  private getWrappedStats(): void {
    if (config.videochat.statsReportTimeInterval) {
      let lastResult: RTCStatsReport;

      WebRTCHelpers.trace('Stats tracker has been started');

      this.statsReportTimer = setInterval(() => {
        this.getStatsCustom(
          lastResult,
          (results, lastResults) => {
            lastResult = lastResults;
            this.session.onCallStatsReport(this.userID, results);
          },
          (err) => {
            WebRTCHelpers.traceError(`Get stats error. ${err.name}: ${err.message}`);
            this.session.onCallStatsReport(this.userID, null, err);
          }
        );
      }, config.videochat.statsReportTimeInterval * 1000);
    }
  }

  set sdpRemote(sdp: string) {
    if (sdp) {
      this.remoteSDP = sdp;
    }
  }

  get sdpRemote(): string | null {
    return this.remoteSDP;
  }

  toString(): string {
    return `sessionID: ${this.session.ID}, userID: ${this.userID}, type: ${this.type}, state: ${this.state}`;
  }

  private getStatsCustom(
    lastResults: RTCStatsReport,
    onSuccess: (results: any, lastResults: any) => void,
    onFail: (err: Error) => void
  ) {
    const obj = { audio: {} as any, video: {} as any, candidate: {} as any };
    const statistic = { local: Object.assign({}, obj), remote: Object.assign({}, obj) };

    if (WebRTCHelpers.getVersionFirefox) {
      const localStream = this.session.localStream;
      const localVideoSettings = localStream?.getVideoTracks()[0].getSettings();

      if (localVideoSettings) {
        statistic.local.video.frameHeight = localVideoSettings.height;
        statistic.local.video.frameWidth = localVideoSettings.width;
      }
    }

    this.getStats().then((results: RTCStatsReport) => {
      results.forEach((result) => {
        let item;

        if (result.bytesReceived && result.type === 'inbound-rtp') {
          item = statistic.remote[result.mediaType];
          item.bitrate = this.getBitratePerSecond(result, lastResults, false);
          item.bytesReceived = result.bytesReceived;
          item.packetsReceived = result.packetsReceived;
          item.timestamp = result.timestamp;
          if (result.mediaType === 'video' && result.framerateMean) {
            item.framesPerSecond = Math.round(result.framerateMean * 10) / 10;
          }
        } else if (result.bytesSent && result.type === 'outbound-rtp') {
          item = statistic.local[result.mediaType];
          item.bitrate = this.getBitratePerSecond(result, lastResults, true);
          item.bytesSent = result.bytesSent;
          item.packetsSent = result.packetsSent;
          item.timestamp = result.timestamp;
          if (result.mediaType === 'video' && result.framerateMean) {
            item.framesPerSecond = Math.round(result.framerateMean * 10) / 10;
          }
        } else if (result.type === 'local-candidate') {
          item = statistic.local.candidate;
          if (result.candidateType === 'host' && result.mozLocalTransport === 'udp' && result.transport === 'udp') {
            item.protocol = result.transport;
            item.ip = result.ipAddress;
            item.port = result.portNumber;
          } else if (!WebRTCHelpers.getVersionFirefox) {
            item.protocol = result.protocol;
            item.ip = result.ip;
            item.port = result.port;
          }
        } else if (result.type === 'remote-candidate') {
          item = statistic.remote.candidate;
          item.protocol = result.protocol || result.transport;
          item.ip = result.ip || result.ipAddress;
          item.port = result.port || result.portNumber;
        } else if (result.type === 'track' && result.kind === 'video' && !WebRTCHelpers.getVersionFirefox) {
          if (result.remoteSource) {
            item = statistic.remote.video;
            item.frameHeight = result.frameHeight;
            item.frameWidth = result.frameWidth;
            item.framesPerSecond = this.getFramesPerSecond(result, lastResults, false);
          } else {
            item = statistic.local.video;
            item.frameHeight = result.frameHeight;
            item.frameWidth = result.frameWidth;
            item.framesPerSecond = this.getFramesPerSecond(result, lastResults, true);
          }
        }
      });
      onSuccess(statistic, results);
    }, onFail);
  }

  private getBitratePerSecond(result: any, lastResults: any, isLocal: boolean): number {
    const lastResult = lastResults.get(result.id);
    const seconds = lastResult ? (result.timestamp - lastResult.timestamp) / 1000 : 5;
    const kilo = 1024;
    const bit = 8;
    const bitrate = !lastResult
      ? 0
      : isLocal
      ? (bit * (result.bytesSent - lastResult.bytesSent)) / (kilo * seconds)
      : (bit * (result.bytesReceived - lastResult.bytesReceived)) / (kilo * seconds);

    return Math.round(bitrate);
  }

  private getFramesPerSecond(result: any, lastResults: any, isLocal: boolean): number {
    const lastResult = lastResults && lastResults.get(result.id);
    const seconds = lastResult ? (result.timestamp - lastResult.timestamp) / 1000 : 5;
    const framesPerSecond = !lastResult
      ? 0
      : isLocal
      ? (result.framesSent - lastResult.framesSent) / seconds
      : (result.framesReceived - lastResult.framesReceived) / seconds;

    return Math.round(framesPerSecond * 10) / 10;
  }

  // original RTCPeerConnection methods
  getSenders(): RTCRtpSender[] {
    return this.original.getSenders?.() ?? [];
  }
  createOffer(options?: RTCOfferOptions | any): Promise<RTCSessionDescriptionInit> {
    return this.original.createOffer?.(options) ?? Promise.resolve({} as RTCSessionDescriptionInit);
  }
  createAnswer(options?: RTCOfferOptions | any): Promise<RTCSessionDescriptionInit> {
    return this.original.createAnswer?.(options) ?? Promise.resolve({} as RTCSessionDescriptionInit);
  }
  setRemoteDescription(description: RTCSessionDescriptionInit | any): Promise<void> {
    return this.original.setRemoteDescription?.(description) ?? Promise.resolve();
  }
  setLocalDescription(description: RTCSessionDescriptionInit | any): Promise<void> {
    return this.original.setLocalDescription?.(description) ?? Promise.resolve();
  }
  getStats(): Promise<RTCStatsReport> {
    return this.original.getStats?.() ?? Promise.resolve(new Map() as any);
  }
  close(): void {
    this.original.close?.();
  }
  addIceCandidate(candidate: RTCIceCandidateInit | any): Promise<void> {
    return this.original.addIceCandidate?.(candidate) ?? Promise.resolve();
  }
  addTrack(track: MediaStreamTrack, stream: MediaStream): RTCRtpSender | any {
    return this.original.addTrack?.(track, stream) ?? {};
  }
  get localDescription(): RTCSessionDescription | { type: string; sdp: any } {
    return this.original.localDescription ?? { type: '', sdp: '' };
  }
  get signalingState(): RTCSignalingState | any {
    return this.original.signalingState ?? 'closed';
  }
  get iceConnectionState(): RTCIceConnectionState | any {
    return this.original.iceConnectionState ?? 'closed';
  }
}
