import { fetchImpl, formDataImpl } from './platform';
import config from './config';
import Utils from './Utils';
import { Proxy, ProxyMethod, ProxyType } from './types';

export default class ProxyService {
  public sdkInstance: Proxy.SDKInstance = {
    config,
    session: null,
  };
  public requestsNumber: number = 0;
  public fetchImpl = fetchImpl;
  private currentUserId: number | undefined;
  private abortControllersMap: Proxy.AbortControllersMap = {};
  public requestMethod: typeof ProxyMethod = ProxyMethod;
  public responseType: typeof ProxyType = ProxyType;

  public setSession(session: Proxy.SDKInstance['session']): void {
    this.sdkInstance.session = session;

    if (session && session.user_id) {
      this.setCurrentUserId(session.user_id);
    }
  }

  public getSession(): Proxy.SDKInstance['session'] {
    return this.sdkInstance.session;
  }

  public setCurrentUserId(userId?: number | null): void {
    this.currentUserId = userId || undefined;
  }

  public getCurrentUserId(): number | undefined {
    return this.currentUserId;
  }

  public logRequest(params: Proxy.Params, requestId: number): void {
    Utils.DLog(`[Request][${requestId}]`, `${params.type || 'GET'} ${params.url}`, params);
  }

  public logResponse(response: any, requestId: number): void {
    Utils.DLog(`[Response][${requestId}]`, response);
  }

  public buildRequestAndURL(params: Proxy.Params): [any, string] {
    const isGetOrHeadType = !params.type || params.type === 'GET' || params.type === 'HEAD';
    const isPostOrPutType = params.type ? params.type === 'POST' || params.type === 'PUT' : false;
    const token = this.sdkInstance && this.sdkInstance.session && this.sdkInstance.session.token;
    const isInternalRequest = params.url.indexOf('s3.amazonaws.com') === -1;
    const isMultipartFormData = params.contentType === false;
    const authKey = params.authKey;

    let requestBody;
    let requestURL = params.url;
    const requestObject: any = {};

    requestObject.method = params.type || 'GET';

    if (params.data) {
      requestBody = this.buildRequestBody(params, isMultipartFormData, isPostOrPutType);

      if (isGetOrHeadType) {
        requestURL += `?${requestBody}`;
      } else {
        requestObject.body = requestBody;
      }
    }

    if (!isMultipartFormData) {
      requestObject.headers = {
        'Content-Type': isPostOrPutType
          ? 'application/json;charset=utf-8'
          : 'application/x-www-form-urlencoded; charset=UTF-8',
      };
    }

    if (isInternalRequest) {
      if (!requestObject.headers) {
        requestObject.headers = {};
      }

      requestObject.headers['CB-SDK'] = `JS ${config.version} - Client`;

      if (token) {
        requestObject.headers['CB-Token'] = token;
      } else if (authKey) {
        requestObject.headers['CB-AuthKey'] = authKey;
      }
    }

    if (config.timeout) {
      requestObject.timeout = config.timeout;
    }

    return [requestObject, requestURL];
  }

  public buildRequestBody(
    params: Proxy.Params,
    isMultipartFormData: boolean,
    isPostOrPutType: boolean
  ): typeof formDataImpl | string | any {
    const data = params.data;
    const useArrayQuery = params.useArrayQuery;

    let dataObject: any;

    if (isMultipartFormData) {
      dataObject = new formDataImpl();

      Object.keys(data).forEach(function (item) {
        if (params.fileToCustomObject && item === 'file') {
          dataObject.append(item, data[item].data, data[item].name);
        } else {
          dataObject.append(item, params.data[item]);
        }
      });
    } else if (isPostOrPutType) {
      dataObject = JSON.stringify(data);
    } else {
      dataObject = this.serializeQueryParams(data, '', useArrayQuery, 0);
    }

    return dataObject;
  }

  public serializeQueryParams(obj: any, prefix: string, useArrayQuery: any, level = 0): string {
    const parts: string[] = [];

    for (let propName in obj) {
      let propQueryName = this.encodeURIComponent(propName);
      if (Utils.isArray(obj)) {
        propQueryName = '';
      }

      const key = prefix ? prefix + `[${propQueryName}]` : propQueryName;
      let value = obj[propName];

      const isArrayVal = Utils.isArray(value);

      if ((isArrayVal && (useArrayQuery || level === 0)) || Utils.isObject(value)) {
        parts.push(this.serializeQueryParams(value, key, useArrayQuery, ++level));
      } else {
        value = isArrayVal ? value.sort().join(',') : value;
        parts.push(`${key}=${this.encodeURIComponent(value)}`);
      }
    }

    return parts.sort().join('&');
  }

  public encodeURIComponent(uriComponent: string | number | boolean): string {
    return encodeURIComponent(uriComponent).replace(/[#$&+,/:;=?@\[\]]/g, (c) => `%${c.charCodeAt(0).toString(16)}`);
  }

  public abortRequest(abortId: string | number): void {
    if (this.abortControllersMap[abortId]) {
      const controllers = this.abortControllersMap[abortId].controllers || [];

      controllers.forEach((controller) => {
        controller.abort();
      });
    }
  }

  public processSuccessfulOrFailedRequest(abort_id?: string | number): void {
    if (!abort_id || !this.abortControllersMap[abort_id]) {
      return;
    }

    const controllers = this.abortControllersMap[abort_id].controllers || [];

    if (!this.abortControllersMap[abort_id].doneRequestsCount) {
      this.abortControllersMap[abort_id].doneRequestsCount = 1;
    } else {
      this.abortControllersMap[abort_id].doneRequestsCount += 1;
    }

    const doneRequestsCount = this.abortControllersMap[abort_id].doneRequestsCount;

    if (doneRequestsCount === controllers.length) {
      delete this.abortControllersMap[abort_id];
    }
  }

  public async ajax<T>(params: Proxy.Params): Promise<T> {
    const requestId = ++this.requestsNumber;

    return new Promise((resolve, reject) => {
      this.logRequest(params, requestId);

      const requestAndURL = this.buildRequestAndURL(params);
      const requestObject = requestAndURL[0];
      const requestURL = requestAndURL[1];
      const abort_id = params.abort_id;

      if (abort_id) {
        let index = 0;

        if (this.abortControllersMap[abort_id]) {
          const controllers = this.abortControllersMap[abort_id].controllers || [];
          this.abortControllersMap[abort_id].controllers.push(new AbortController());
          index = controllers.length - 1;
        } else {
          this.abortControllersMap[abort_id] = {
            controllers: [new AbortController()],
          };
        }
        const signal = this.abortControllersMap[abort_id].controllers[index].signal;
        Object.assign(requestObject, { signal });
      }

      let response: any;

      // The Promise returned from fetch() won’t reject on HTTP error
      // status even if the response is an HTTP 404 or 500.
      // Instead, it will resolve normally (with ok status set to false),
      // and it will only reject on network failure or if anything prevented the request from completing.
      fetchImpl(requestURL, requestObject)
        .then((resp: any) => {
          response = resp;
          const dataType = params.dataType || ProxyType.JSON;
          return dataType === ProxyType.TEXT ? response.text() : response.json();
        })
        .then((body: any) => {
          this.processSuccessfulOrFailedRequest(abort_id);
          if (!response.ok) {
            this.processAjaxError(response, body, null, reject, resolve, params, requestId);
          } else {
            this.processAjaxResponse(body, resolve, requestId);
          }
        })
        .catch((error: any) => {
          this.processSuccessfulOrFailedRequest(abort_id);
          this.processAjaxError(response, ' ', error, reject, resolve, params, requestId);
        });
    });
  }

  public processAjaxResponse(body: any, resolve: (value: any) => void, requestId: number): void {
    const responseBody = body && body !== ' ' ? body : 'empty body';
    this.logResponse(responseBody, requestId);

    resolve(body);
  }

  public processAjaxError(
    response: Response | null,
    body: any,
    error: any,
    reject: (reason?: any) => void,
    resolve: (value: any) => void,
    params: Proxy.Params,
    requestId: number
  ): void {
    if (!response && error && !error.code) {
      reject(error);
      return;
    }

    const statusCode = response?.status;
    const errorObject = {
      code: (response && statusCode) || (error && error.code),
      info: (body && typeof body === 'string' && body !== ' ' ? JSON.parse(body) : body) || (error && error.errno),
    };

    const responseBody = body || error || body.errors;
    this.logResponse(responseBody, requestId);

    if (response?.url.indexOf(config.urls.session) === -1) {
      if (this.isExpiredSessionError(errorObject) && typeof config.on.sessionExpired === 'function') {
        this.handleExpiredSessionResponse(errorObject, null, reject, resolve, params);
      } else {
        reject(errorObject);
      }
    } else {
      reject(errorObject);
    }
  }

  public handleExpiredSessionResponse(
    error: any,
    response: any,
    reject: (reason?: any) => void,
    resolve: (value: any) => void,
    params: Proxy.Params
  ): void {
    const handleResponse = () => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    };

    const retryCallback = (session: Proxy.SDKInstance['session']) => {
      if (session) {
        this.setSession(session);
        this.ajax(params).then(resolve).catch(reject);
      }
    };

    if (typeof config.on.sessionExpired === 'function') {
      config.on.sessionExpired(handleResponse, retryCallback);
    }
  }

  private isExpiredSessionError(error: { code?: number; info?: { errors?: { base?: string[] } } }): boolean {
    try {
      return error && error.code === 401 && error.info?.errors?.base?.[0] === 'Required session does not exist';
    } catch {
      return false;
    }
  }
}
