import EventEmitter from 'eventemitter3';
import { adapter, MediaStream } from '../platform';
import config from '../config';
import JanusEngine from './janus';
import Utils from '../Utils';
import { Config, Janus, JanusDebugMode, JanusEvent, JanusMediaTrackReason } from '../types';

export default class JanusClient {
  public token: Config.Credentials['token'];
  public server: Config.Conference['server'];
  public debug: Config.JanusDebug;
  public engine!: JanusEngine;
  public videoRoomPlugin: any = null;
  public isOnlyAudio: boolean = false;
  public currentRoomId: string | null = null;
  public currentUserId: number | string | null = null;
  public remoteFeeds: { [userId: number | string]: any } = {};
  public remoteJseps: { [userId: number | string]: any } = {};
  public remoteFeedsAttachingInProgress: { [userId: number | string]: any } = {};
  public bitrateTimers: { [userId: number | string]: NodeJS.Timeout | null } = {};
  public emitter: EventEmitter = new EventEmitter();

  constructor(token: Config.Credentials['token']) {
    if (!Utils.env.isReactNative && !adapter) {
      throw 'Error: in order to use this library please connect adapter.js. More info https://github.com/webrtc/adapter';
    }

    this.token = token;
    this.server = config.conference.server;
    this.debug = config.conference.debug ?? JanusDebugMode.ALL;

    if (!this.server) {
      throw "'server' parameter is mandatory";
    } else if (this.server.includes('http')) {
      this.server += '/janus';
    }
  }

  public createSession({
    success = () => {},
    error = () => {},
    destroyed = () => {},
    timeoutSessionCallback = () => {},
  }: Janus.InitCallbacks): void {
    JanusEngine.init({
      debug: this.debug,
      callback: () => {
        if (!JanusEngine.isWebrtcSupported()) {
          Utils.safeCallbackCall(error)(`Your browser does not support WebRTC, so you can't use this functionality.`);
          return;
        }

        this.engine = new JanusEngine({
          server: this.server,
          iceServers: config.videochat.iceServers,
          token: this.token,
          success: () => Utils.safeCallbackCall(success)(),
          error: (err) => Utils.safeCallbackCall(error)(err),
          destroyed: () => Utils.safeCallbackCall(destroyed)(),
          timeoutSessionCallback: () => Utils.safeCallbackCall(timeoutSessionCallback)(),
        });
      },
    });
  }

  public getSessionId(): string | null {
    return this.engine?.getSessionId() ?? null;
  }

  public destroySession({ success = () => {}, error = () => {} }: Janus.SuccessErrorCallbacks): void {
    this.engine.destroy({
      success: () => Utils.safeCallbackCall(success)(),
      error: (err) => Utils.safeCallbackCall(error)(err),
    });
  }

  public attachVideoConferencingPlugin(
    isRemote: boolean,
    userId: number,
    skipMedia: boolean,
    callbacks: Janus.PluginCallbacks
  ): void {
    let remoteFeed: any = null;

    const localStream = callbacks.localStream;
    delete callbacks.localStream;

    const displayName = callbacks.displayName;
    delete callbacks.displayName;

    this.engine.attach({
      plugin: 'janus.plugin.videoroom',
      success: (pluginHandle) => {
        if (isRemote) {
          remoteFeed = pluginHandle;
          remoteFeed.userId = userId;
          this.remoteFeedsAttachingInProgress[userId] = remoteFeed;

          const listen = {
            request: 'join',
            room: this.currentRoomId,
            ptype: 'listener',
            feed: userId,
            display: displayName,
          };

          remoteFeed?.send({ message: listen });
        } else {
          this.videoRoomPlugin = pluginHandle;
        }

        Utils.safeCallbackCall(callbacks.success)();
      },
      error: (error) => {
        Utils.safeCallbackCall(callbacks.error)(error);
      },
      consentDialog: (on) => {
        Utils.safeCallbackCall(callbacks.consentDialog)(on);
      },
      mediaState: (medium, on) => {
        Utils.safeCallbackCall(callbacks.mediaState)(medium, on);
      },
      webrtcState: (on) => {
        Utils.safeCallbackCall(callbacks.webrtcState)(on);
      },
      slowLink: (uplink, nacks) => {
        Utils.safeCallbackCall(callbacks.slowLink)(uplink, nacks);
      },
      iceState: (iceConnectionState) => {
        Utils.safeCallbackCall(callbacks.iceState)(iceConnectionState);
      },
      onmessage: (msg, jsep) => {
        const event = msg['videoroom'];

        if (isRemote) {
          if (event) {
            // Remote feed attached
            if (event === 'attached') {
              const feedId = msg['id'];
              this.remoteFeeds[feedId] = this.remoteFeedsAttachingInProgress[feedId];
              this.remoteFeedsAttachingInProgress[feedId] = null;
            } else if (msg['error']) {
              Utils.safeCallbackCall(callbacks.error)(msg['error']);
            }
          }

          if (jsep) {
            const feedId = msg['id'];

            // ICE restart case
            if (!feedId) {
            }

            this.remoteJseps[feedId] = jsep;

            this.createAnswer(
              {
                remoteFeed: this.remoteFeeds[feedId],
                jsep,
              },
              localStream as MediaStream,
              {
                success: () => {},
                error: (error) => {
                  Utils.safeCallbackCall(callbacks.error)(error);
                },
              }
            );
          }

          // local feed
        } else {
          if (event) {
            // We JOINED
            if (event === 'joined') {
              const media = skipMedia ? { audio: false, video: false } : { audio: true, video: true };
              const existedStream = skipMedia ? undefined : localStream;
              const offerParams = { media, stream: existedStream };
              this.createOffer(offerParams, {
                success: () => {
                  if (msg['publishers']) {
                    const publishers = msg['publishers'];
                    for (const f in publishers) {
                      const userId = publishers[f]['id'];
                      const userDisplayName = publishers[f]['display'];
                      this.emitter.emit(JanusEvent.PARTICIPANT_JOINED, userId, userDisplayName, true);
                    }
                  }
                },
                error: (error) => {
                  Utils.safeCallbackCall(callbacks.error)(error);
                },
              });

              // We JOINED and now receiving who is online
            } else if (event === 'event') {
              // Any new feed to attach to?
              if (msg['publishers']) {
                const publishers = msg['publishers'];

                for (const f in publishers) {
                  const userId = publishers[f]['id'];
                  const userDisplayName = publishers[f]['display'];

                  this.emitter.emit(JanusEvent.PARTICIPANT_JOINED, userId, userDisplayName, false);
                }

                // Someone is LEAVING
              } else if (msg['leaving']) {
                // One of the publishers has gone away?
                const feedId = msg['leaving'];
                const success = this.detachRemoteFeed(feedId);
                if (success) {
                  this.emitter.emit(JanusEvent.PARTICIPANT_LEFT, feedId, null);
                }
              } else if (msg['unpublished']) {
                // One of the publishers has gone away?
                const feedId = msg['unpublished'];
                if (feedId != 'ok') {
                  const success = this.detachRemoteFeed(feedId);
                  if (success) {
                    this.emitter.emit(JanusEvent.PARTICIPANT_LEFT, feedId, null);
                  }
                }
              } else if (msg['error']) {
                Utils.DLog('[janus error message]', msg['error']);
                // #define VIDEOROOM_ERROR_ID_EXISTS			436
                // #define VIDEOROOM_ERROR_UNAUTHORIZED		433
                //
                this.emitter.emit(JanusEvent.ERROR, msg);
                Utils.safeCallbackCall(callbacks.error)(msg['error']);
              }
            }
          }

          if (jsep) {
            this.videoRoomPlugin.handleRemoteJsep({ jsep: jsep });

            // TODO:
            // handle wrong or unsupported codecs here...
            // const video = msg['video_codec']
            // if(mystream && mystream.getVideoTracks() && mystream.getVideoTracks().length > 0 && !video) {
            // 		'Our video stream has been rejected, viewers won't see us'
            // }
          }
        }
      },
      onlocaltrack: (track, on) => {
        Utils.DLog('[onlocaltrack]', track, on);
        this.onLocalTrack(track, on);
      },
      onremotetrack: (track, mid, on, metadata) => {
        Utils.DLog('[onremotetrack]', track, mid, on, metadata);
        this.onRemoteTrack(remoteFeed, track, mid, on, metadata);
      },
      ondataopen: (channelLabel) => {
        Utils.DLog('[ondataopen]', channelLabel);
        this.emitter.emit(JanusEvent.DATA_CHANNEL_OPEN, channelLabel);
      },
      ondata: (data, channelLabel) => {
        Utils.DLog('[ondata]', channelLabel, data);
        this.emitter.emit(JanusEvent.DATA_CHANNEL_MESSAGE, channelLabel, data);
      },
      oncleanup: () => {
        Utils.safeCallbackCall(callbacks.oncleanup)();
      },
      detached: () => {},
    });
  }

  public onLocalTrack(track: MediaStreamTrack, on: any): void {
    // this.emitter.emit(JanusEvent.LOCAL_STREAM, track);
  }

  public onRemoteTrack(remoteFeed: any, track: MediaStreamTrack, mid: any, on: any, metadata: any): void {
    const eventType = metadata && metadata.reason;

    if (eventType === JanusMediaTrackReason.CREATED) {
      const isStreamNoExistedBefore = !remoteFeed.stream || !remoteFeed.tracks;
      if (isStreamNoExistedBefore) {
        remoteFeed.tracks = { [mid]: track };
        remoteFeed.stream = new MediaStream([track]);
      } else {
        remoteFeed.tracks[mid] = track;
        remoteFeed.stream.addTrack(track);
      }
      if (isStreamNoExistedBefore) {
        this.emitter.emit(JanusEvent.REMOTE_STREAM, remoteFeed.userId, remoteFeed.stream);
      }
    } else if (eventType === JanusMediaTrackReason.ENDED) {
      delete remoteFeed.tracks[mid];

      const trackToRemove = remoteFeed.stream.getTracks().find((streamTrack) => streamTrack.kind === track.kind);
      remoteFeed.stream.removeTrack(trackToRemove);
    }

    this.emitter.emit(JanusEvent.REMOTE_TRACKS_UPDATED, remoteFeed.userId, track, eventType);
  }

  public getPluginId(): string | null {
    return this.videoRoomPlugin?.getId() ?? null;
  }

  public detachVideoConferencingPlugin(callbacks: Janus.SuccessErrorCallbacks): void {
    const clean = () => {
      this.videoRoomPlugin = null;

      // detach all remote feeds
      Object.keys(this.remoteFeeds).forEach((userId) => {
        this.detachRemoteFeed(Number(userId));
      });

      this.remoteFeeds = {};
      this.remoteJseps = {};
      /*
       * Deprecated
      this.currentMediaDeviceId = null
       */
    };

    this.videoRoomPlugin.detach({
      success: () => {
        clean();

        Utils.safeCallbackCall(callbacks.success)();
      },
      error: (error) => {
        clean();

        Utils.safeCallbackCall(callbacks.error)(error);
      },
    });
  }

  public join(roomId: string, userId: number, isOnlyAudio: boolean, callbacks: Janus.RequestCallbacks): void {
    const displayName = callbacks.displayName;
    delete callbacks.displayName;

    this.isOnlyAudio = isOnlyAudio;

    Utils.DLog('isOnlyAudio: ' + this.isOnlyAudio);

    const joinEvent = {
      request: 'join',
      room: roomId,
      ptype: 'publisher',
      id: userId,
      display: displayName,
    };

    this.videoRoomPlugin.send({
      message: joinEvent,
      success: (resp) => {
        this.currentRoomId = roomId;
        this.currentUserId = userId;
        Utils.safeCallbackCall(callbacks.success)();
      },
      error: (error) => {
        Utils.safeCallbackCall(callbacks.error)(error);
      },
    });
  }

  public leave(callbacks: Janus.RequestCallbacks): void {
    Utils.DLog('leave');

    if (!this.engine.isConnected()) {
      Utils.safeCallbackCall(callbacks.success)();
      return;
    }

    const leaveEvent = {
      request: 'leave',
      room: this.currentRoomId,
      id: this.currentUserId,
    };

    if (this.videoRoomPlugin) {
      this.videoRoomPlugin.send({ message: leaveEvent });
    }
    this.currentRoomId = null;
    this.currentUserId = null;

    Utils.safeCallbackCall(callbacks.success)();
  }

  public listOnlineParticipants(roomId: string, callbacks: Janus.SuccessErrorCallbacks): void {
    const listRequest = { request: 'listparticipants', room: roomId };

    this.videoRoomPlugin.send({
      message: listRequest,
      success: (data) => {
        const participants = data ? data.participants : [];
        Utils.safeCallbackCall(callbacks.success)(participants);
      },
      error: (error) => {
        Utils.safeCallbackCall(callbacks.error)(error);
      },
    });
  }

  public toggleAudioMute(): boolean {
    const muted = this.videoRoomPlugin.isAudioMuted();
    if (muted) {
      this.videoRoomPlugin.unmuteAudio();
    } else {
      this.videoRoomPlugin.muteAudio();
    }
    return this.videoRoomPlugin.isAudioMuted();
  }

  public isAudioMuted(): boolean {
    return this.videoRoomPlugin.isAudioMuted();
  }

  public toggleRemoteAudioMute(userId: number): boolean {
    const remoteFeed = this.remoteFeeds[userId];
    if (!remoteFeed) {
      return false;
    }

    const audioTracks = remoteFeed.stream.getAudioTracks();
    if (audioTracks && audioTracks.length > 0) {
      for (let i = 0; i < audioTracks.length; ++i) {
        audioTracks[i].enabled = !audioTracks[i].enabled;
      }
      return !audioTracks[0].enabled;
    }

    return false;
  }

  public isRemoteAudioMuted(userId: number): boolean {
    const remoteFeed = this.remoteFeeds[userId];
    if (!remoteFeed) {
      return false;
    }

    const audioTracks = remoteFeed.stream.getAudioTracks();
    if (audioTracks && audioTracks.length > 0) {
      return !audioTracks[0].enabled;
    }

    return false;
  }

  public toggleVideoMute(): boolean {
    const muted = this.videoRoomPlugin.isVideoMuted();
    if (muted) {
      this.videoRoomPlugin.unmuteVideo();
    } else {
      this.videoRoomPlugin.muteVideo();
    }
    return this.videoRoomPlugin.isVideoMuted();
  }

  public isVideoMuted(): boolean {
    return this.videoRoomPlugin.isVideoMuted();
  }

  public toggleRemoteVideoMute(userId: number): boolean {
    const remoteFeed = this.remoteFeeds[userId];
    if (!remoteFeed) {
      return false;
    }

    const videoTracks = remoteFeed.stream.getVideoTracks();
    if (videoTracks && videoTracks.length > 0) {
      for (let i = 0; i < videoTracks.length; ++i) {
        videoTracks[i].enabled = !videoTracks[i].enabled;
      }
      return !videoTracks[0].enabled;
    }

    return false;
  }

  public isRemoteVideoMuted(userId: number): boolean {
    const remoteFeed = this.remoteFeeds[userId];
    if (!remoteFeed) {
      return false;
    }

    const videoTracks = remoteFeed.stream.getVideoTracks();
    if (videoTracks && videoTracks.length > 0) {
      return !videoTracks[0].enabled;
    }

    return false;
  }

  public sendKeyframeRequest(roomId: string, callbacks: Janus.SuccessErrorCallbacks): void {
    const configureRequest = {
      request: 'configure',
      room: roomId,
      keyframe: true,
    };

    this.videoRoomPlugin.send({
      message: configureRequest,
      success: (response) => {
        Utils.safeCallbackCall(callbacks.success)(response);
      },
      error: (error) => {
        Utils.safeCallbackCall(callbacks.error)(error);
      },
    });
  }

  public getTracksFromStream(stream: MediaStream): { type: string; capture: MediaStreamTrack; recv: boolean }[] {
    const tracks: { type: string; capture: MediaStreamTrack; recv: boolean }[] = [];

    const audioTracks = stream.getAudioTracks();
    if (audioTracks.length) {
      const audioTrack = audioTracks[0];
      tracks.push({ type: 'audio', capture: audioTrack, recv: false });
    }

    const videoTracks = stream.getVideoTracks();
    if (videoTracks.length) {
      const videoTrack = videoTracks[0];
      tracks.push({ type: 'video', capture: videoTrack, recv: false });
    }

    return tracks;
  }

  public createOffer(
    mediaParams: { stream?: MediaStream; media: { audio: boolean; video: boolean }; replace?: boolean },
    callbacks: Janus.SuccessErrorCallbacks
  ): void {
    Utils.DLog('[JanusWrapper][createOffer]', mediaParams);

    const { stream: existedStream, media, replace } = mediaParams;

    const createOfferParams: any = { tracks: [{ type: 'data' }] };

    if (existedStream) {
      const tracksFromStream = this.getTracksFromStream(existedStream);
      createOfferParams.tracks = createOfferParams.tracks.concat(tracksFromStream);
    } else if (media) {
      const tracksFromParams: { type: string; capture: boolean; recv: boolean; replace: boolean }[] = [];
      if (media.audio) {
        tracksFromParams.push({
          type: 'audio',
          capture: media.audio,
          recv: false,
          replace: !!replace,
        });
      }
      if (media.video) {
        tracksFromParams.push({
          type: 'video',
          capture: media.video,
          recv: false,
          replace: !!replace,
        });
      }
      createOfferParams.tracks = createOfferParams.tracks.concat(tracksFromParams);
    } else {
      createOfferParams.tracks = createOfferParams.tracks.concat([
        { type: 'audio', capture: true, recv: false, replace: !!replace },
        { type: 'video', capture: true, recv: false, replace: !!replace },
      ]);
    }

    Utils.DLog('[JanusWrapper][createOffer][params]', createOfferParams);

    createOfferParams.customizeSdp = (jsep) => {};

    createOfferParams.success = (jsep) => {
      const publish = {
        request: 'configure',
        audio: !!media.audio,
        video: !!media.video,
      };
      Utils.DLog('[JanusWrapper][createOffer][success]', publish);

      this.videoRoomPlugin.send({ message: publish, jsep: jsep });

      Utils.safeCallbackCall(callbacks.success)();
    };

    createOfferParams.error = (error) => {
      Utils.DLog('[JanusWrapper][createOffer][error]', error);
      if (media.audio) {
        this.createOffer({ media: { video: false, audio: false } }, callbacks);
      } else {
        Utils.safeCallbackCall(callbacks.error)(error);
      }
    };

    this.videoRoomPlugin.createOffer(createOfferParams);
  }

  public getTracksMidsFromStream(stream: MediaStream): { type: string; mid: string; recv: boolean }[] {
    const tracks: { type: string; mid: string; recv: boolean }[] = [];

    const audioTracks = stream.getAudioTracks();
    if (audioTracks.length) {
      const audioTrack = audioTracks[0];
      tracks.push({ type: 'audio', mid: audioTrack.id, recv: true });
    }

    const videoTracks = stream.getVideoTracks();
    if (videoTracks.length) {
      const videoTrack = videoTracks[0];
      tracks.push({ type: 'video', mid: videoTrack.id, recv: true });
    }

    return tracks;
  }

  public createAnswer(
    { remoteFeed, jsep }: any,
    existedStream: MediaStream,
    callbacks: Janus.SuccessErrorCallbacks
  ): void {
    Utils.DLog('[JanusWrapper][createAnswer]', jsep, existedStream);
    let tracks: any = [{ type: 'data' }];

    if (existedStream) {
      const tracksFromStream = this.getTracksMidsFromStream(existedStream);
      tracks = tracks.concat(tracksFromStream);
    }

    Utils.DLog('[JanusWrapper][createAnswer][tracks]', tracks);

    remoteFeed.createAnswer({
      jsep: jsep,
      tracks: tracks,
      success: (jsep) => {
        const body = { request: 'start', room: this.currentRoomId };
        Utils.DLog('[JanusWrapper][createAnswer][success]', body);

        remoteFeed.send({ message: body, jsep: jsep });

        Utils.safeCallbackCall(callbacks.success)();
      },
      error: (error) => {
        Utils.DLog('[JanusWrapper][createAnswer][error]', error);
        Utils.safeCallbackCall(callbacks.error)(error);
      },
    });
  }

  public detachRemoteFeed(userId: number): boolean {
    const remoteFeed = this.remoteFeeds[userId];
    if (remoteFeed) {
      remoteFeed.detach();
      this.remoteFeeds[userId] = null;
      this.remoteJseps[userId] = null;
      return true;
    }
    return false;
  }

  public getUserBitrate(userId: number): any {
    const remoteFeed = this.remoteFeeds[userId];
    return remoteFeed.getBitrate();
  }

  public getVolume(resultCallback: (value: any) => void): void {
    return this.videoRoomPlugin.getLocalVolume(null, resultCallback);
  }

  public getUserVolume(userId: number, resultCallback: (value: any) => void): void {
    const remoteFeed = this.remoteFeeds[userId];
    return remoteFeed.getRemoteVolume(null, resultCallback);
  }

  public showBitrate(userId: number, element: any): void {
    const remoteFeed = this.remoteFeeds[userId];

    if (
      !Utils.env.isReactNative &&
      (adapter.browserDetails.browser === 'chrome' || adapter.browserDetails.browser === 'firefox')
    ) {
      this.bitrateTimers[userId] = setInterval(() => {
        const bitrate = remoteFeed.getBitrate();
        element.text(bitrate);
      }, 1000);
    }
  }

  public hideBitrate(userId: number, element: any): void {
    if (this.bitrateTimers[userId]) {
      clearInterval(this.bitrateTimers[userId]);
    }
    this.bitrateTimers[userId] = null;
    element.text = null;
  }

  public on(eventType: JanusEvent, listener: (...args: any[]) => void): EventEmitter {
    return this.emitter.addListener(eventType, listener);
  }

  public removeAllListeners(eventType?: JanusEvent): EventEmitter {
    return this.emitter.removeAllListeners(eventType);
  }
}
