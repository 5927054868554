import ProxyService from './Proxy';
import config from './config';
import { ProxyMethod, LinkPreview, LinkPreviewCache } from './types';

export default class LinkPreviewService {
  public proxy: ProxyService;
  public cache: LinkPreviewCache = new Map();

  readonly route = config.urls.unfurl;
  readonly server = config.linkpreview.server;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public async get(url: string): Promise<LinkPreview> {
    if (!this.cache.has(url)) {
      let linkPreview: LinkPreview;

      try {
        const ajaxParams = {
          type: ProxyMethod.POST,
          url: `${this.server}/${this.route}`,
          data: { url },
        };

        linkPreview = (await this.proxy.ajax(ajaxParams)) as LinkPreview;
      } catch (error) {
        const title = this.getHostFromUrl(url);

        linkPreview = { url, title, description: '', images: [] };
      } finally {
        this.cache.set(url, linkPreview);
      }
    }

    return this.cache.get(url)!;
  }

  private getHostFromUrl(url: string): string {
    if (typeof URL === 'function') {
      return new URL(url).hostname;
    }

    const match = url.match(/^(?:https?:\/\/)?([^\/]+)/);
    return match ? match[1] : url;
  }
}
