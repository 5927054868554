import ProxyService from './Proxy';
import Utils from './Utils';
import config from './config';
import { AddressBook, ProxyMethod } from './types';

export default class AddressBookService {
  public proxy: ProxyService;
  readonly route = config.urls.addressbook;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public async uploadAddressBook(
    list: AddressBook.Contact[] = [],
    params: AddressBook.UploadParams = {}
  ): Promise<AddressBook.UploadResponse> {
    if (!Utils.isArray(list)) {
      throw new Error('First parameter must be an Array.');
    }

    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route),
      data: { contacts: list, ...params },
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async get(udid?: string): Promise<AddressBook.Contact[]> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route),
      data: udid ? { udid } : undefined,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async getRegisteredUsers(
    params: AddressBook.GetRegisteredUsersParams = false
  ): Promise<AddressBook.GetRegisteredUsersResponse> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route, 'registered_users'),
      data: typeof params === 'boolean' ? { compact: params ? 1 : 0 } : params,
    };

    return this.proxy.ajax(ajaxParams);
  }
}
