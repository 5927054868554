import ProxyService from './Proxy';
import config from './config';
import Utils from './Utils';
import { EmptyResponse, ProxyMethod, ProxyType, Whiteboard } from './types';

export default class WhiteboardService {
  public proxy: ProxyService;
  readonly route = config.urls.whiteboards;
  readonly server = config.whiteboard.server;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public getURL({ id, title, username }: Whiteboard.GetUrlParams): string {
    return `${this.server}?whiteboardid=${id}&username=${username}&title=${title}`;
  }

  public async get(paramsOrId: Whiteboard.GetParams): Promise<Whiteboard.Item[]> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route),
      data: typeof paramsOrId === 'string' ? { chat_dialog_id: paramsOrId } : paramsOrId,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async create(params: Whiteboard.CreateParams): Promise<Whiteboard.Item> {
    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async update(id: string, params: Whiteboard.UpdateParams): Promise<Whiteboard.Item> {
    const ajaxParams = {
      type: ProxyMethod.PUT,
      url: Utils.getUrl(this.route, id),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async delete(id: string): Promise<EmptyResponse> {
    const ajaxParams = {
      type: ProxyMethod.DELETE,
      url: Utils.getUrl(this.route, id),
      dataType: ProxyType.TEXT,
    };

    return this.proxy.ajax(ajaxParams);
  }
}
